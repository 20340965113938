$(document).ready(function(){
  getEditorChoice(); 
})
function getEditorChoice() {
  actionWebApiRestrict('/v1/stream/article/editor-choice/home',{}, 'GET').done(function(json) {
    if (json.status == 600) {
      let articles = json.data;
      $.each(articles, function(key, value) {
        var appendText = drawEditorChoice(value, key);
        $('#editor-choice').append(appendText)
      })
      $('.home-editor-pick-container').slick({
        speed: 300,
        infinite: false,
        arrows: false,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              draggable: false
            }
          }
        ]
      })
      $('.lazy-choice').lazyload();
      $('.home-editor-pick-container').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.lazy-choice').trigger("appear")
      });
    }
  });
}
function drawEditorChoice(value, key){
  var lengthTitle = value.title.length;
  var setTitle = ''
  if(lengthTitle > 100){ 
    setTitle = htmlSubstring(value.richtext_title,100)+'...'
  }else{
    setTitle = value.richtext_title
  }
  var lengthTaicing = value.taicing.length;
  var trimTaicing = value.taicing.substring(0,200)
  var addDot = ''
  if(lengthTaicing > 200){ addDot = '...'}
  var appendText = '';
  appendText += '<div class="home-editor-choice-list" >';
    appendText += '<a href="'+value.url+'" onclick="panelTrackingPushSection(\'Pilihan Redaksi\',\''+value.url+'\',\'Dibawah Historia Channel\',\''+value.title+'\')">'
      appendText += '<img src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs=" class="lazy-choice" data-original="'+value.editor_cover.original+'" alt="cover '+setTitle+'">';
      appendText += '<span class="home-editor-choice-content-detail">';
        appendText += '<span class="home-editor-choice-content-title"><h5>'+setTitle+'</h5></span>';
        appendText += '<span class="home-editor-choice-content-desc"><p>'+trimTaicing+addDot +'</p></span>';
      appendText += '</span>';
    appendText += '</a>'
  appendText += '</div>'
  return appendText;
}

var panelTrackingPushSection = function(item, target, position, title) {
  dataLayer.push({
    'event': 'paneltracking',
    'panelname': item,
    'panelposition': position,
    'panelpage_url': target,
    'panelpage_title': title
  });
}
